<template>
    <v-app>
        <div>
            <Header/>
        </div>
        <h3 class="podnaslov">VRHUNSKA KVALITETA UZ POVOLJNE CIJENE</h3>
        <main>
            <div class="text_container">
                <div class="text">
                    Dobrodošli na stranice tvrtke Pećnik d.o.o. koja je u Hrvatskoj prisutna više od 25 godina. Nalazimo se u okolici Zagreba, sa sjedištem u gradu Sveta Nedelja na adresi Srebrnjak 6c. <br/><br/>
                    Pećnik d.o.o. je ekskluzivni zastupnik njemačke tvrtke FRANK koja je specijalizirana za proizvodnju profesionalnih visokotlačnih perača, perača s parom te samoposlužnih stanica za pranje najviše kvalitete. Perači FRANK mogu se koristiti u mnogim granama industrije poput: poljoprivredne, transportne i automobilske industrije kao i u proizvodnji hrane i pića.<br/><br/>
                    Osim FRANK perača, u ponudi imamo i usisavače, strojeve za čišćenje tvrdih i mekih podova u svim područjima gospodarstva te motorne kosilice i motorne pile drugih renomiranih brandova poput Wap Alto, Nilfisk Alto, Kränzle, Kärcher, Annovi Reverberi i Stihl za koje kod nas možete pronaći sve potrebne rezervne dijelove.<br/><br/>
                    Kod nas možete nabaviti stroj zamjenom 'staro za novo' kao i 'staro za staro'... <br/><br/>
                </div>
            </div>
        </main>
        <div>
            <Footer/>
        </div>
    </v-app>
</template>

<script>
    import Header from '@/views/Header';
    import Footer from '@/views/Footer';
    
    export default {
        name: 'App',

        components: {
            Header,
            Footer
        },

        data: () => {
            return {
                
            }
        },
        created () {
        }
    }
</script>

<style>
main {
    padding-top: 0.5rem;
    padding-bottom: 5rem;
}
.naslov {
    margin-left: 22rem;
}
.text_container {
    margin-right: 17rem;
    margin-left: 17rem;
    margin-top: 2rem;
    text-align: left;
    border: solid 0.4em white;
    border-top-color: black;
    border-bottom-color: black;
}
.text {
    margin-right: 5rem;
    margin-left: 5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 16px;
}
.podnaslov {
    text-align: left;
    margin-left: 22rem;
    margin-top: 2rem;
    font-size: 25px;
    color: black;
}

@media only screen and (max-width: 1200px) {
    [class*="text_container"] {
        margin-right: 8rem;
        margin-left: 8rem;
    }
    [class*="naslov"] {
        margin-left: 9rem;
    }
    [class*="text"] {
        margin-right: 3rem;
        margin-left: 3rem;
    }
}

@media only screen and (max-width: 768px) {
    main {
        padding-bottom: 1.5rem;
    }
    [class*="text_container"] {
        margin-right: 2rem;
        margin-left: 2rem;
        margin-top: 1rem;
    }
    [class*="naslov"] {
        margin-left: 3.1rem;
    }
    [class*="text"] {
        margin-right: 1.3rem;
        margin-left: 1.3rem;
        font-size: 14px;
    }
    .podnaslov {
        margin-left: 3.2rem;
        margin-right: 2rem;
        font-size: 20px;
    }
}
</style>
